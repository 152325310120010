define("@woody-lite/addon-tasks/models/task", ["exports", "ember-object-data-model/models/base", "@ember/object", "@ember/service"], function (_exports, _base, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const HOUR_DAY = 'HH:mm';
  const TASKS_METADATA = {
    TCTF_TASK_002: {
      title: 'label.signModule.taskHeaders.creditLimitModification',
      action: 'goToTaskDetail'
    },
    KUUD_TC_Task1_ID: {
      title: 'label.signModule.taskHeaders.accountTransfer',
      action: 'goToIncidents'
    },
    KPAP_TASK_001: {
      title: 'label.signModule.taskHeaders.requestOperation',
      action: 'goToTaskDetail'
    },
    KPAP_TASK_003: {
      title: 'label.signModule.taskHeaders.requestOperation003',
      action: 'goToTaskDetail'
    },
    'KBYR_Task1_ID-KGCR_CP001': {
      title: 'label.signModule.taskHeaders.requestRecruitmentAccount',
      action: 'goToTaskDetail'
    },
    'KBYR_Task1_ID-KGCR_00812': {
      title: 'label.signModule.taskHeaders.requestRecruitmentCard',
      action: 'goToTaskDetail'
    }
  };
  let TaskModel = (_class = class TaskModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _defineProperty(this, "timeFormat", HOUR_DAY);
    }
    get action() {
      var _TASKS_METADATA$get;
      return (_TASKS_METADATA$get = TASKS_METADATA[(0, _object.get)(this, 'formatId')]) === null || _TASKS_METADATA$get === void 0 ? void 0 : _TASKS_METADATA$get.action;
    }
    get displayDataId() {
      return (0, _object.get)(this, 'description');
    }
    get creationDate() {
      return (0, _object.get)(this, 'createdDate');
    }
    get expirationDate() {
      return (0, _object.get)(this, 'expireDate');
    }
    get title() {
      var _TASKS_METADATA$get2;
      return (_TASKS_METADATA$get2 = TASKS_METADATA[(0, _object.get)(this, 'formatId')]) === null || _TASKS_METADATA$get2 === void 0 ? void 0 : _TASKS_METADATA$get2.title;
    }
    accept() {
      return this.provider.push('tasks', '_acceptTask', this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = TaskModel;
});