define("@woody-lite/core-session/decorators/akamai-retry", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = akamaiRetry;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const HTTP_FORBIDDEN = 403;
  function akamaiRetry() {
    return function (target) {
      var _class, _descriptor;
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "slinky", _descriptor, this);
        }
        async fail() {
          try {
            return await super.fail(...arguments);
          } catch (e) {
            if (this.slinky.isConnected && e.status === HTTP_FORBIDDEN && this.retries < 1) {
              return this.retry({
                retries: 2
              });
            }
            throw e;
          }
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      })), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});