define("@woody-lite/core-session/components/modal-safe-tap-otp", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@glimmer/tracking", "@ember/object", "ember-concurrency", "@woody-lite/core-session/errors/user-choose-signature-method", "@woody-lite/core-session/errors/safetap-technical", "@woody-lite/core-session/constants", "@woody-lite/core-session/templates/modal-safe-tap-otp"], function (_exports, _modal, _service, _tracking, _object, _emberConcurrency, _userChooseSignatureMethod, _safetapTechnical, _constants, _modalSafeTapOtp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const USER_CREDENTIALS_SMC = 'SMC201600442';
  let ModalSafeTapOtpComponent = (_class = class ModalSafeTapOtpComponent extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);
      _defineProperty(this, "layout", _modalSafeTapOtp.default);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "veil", _descriptor4, this);
      _defineProperty(this, "resource", null);
      _defineProperty(this, "challenge", '');
      _defineProperty(this, "publicServerKey", '');
      _initializerDefineProperty(this, "experience", _descriptor5, this);
      _initializerDefineProperty(this, "isRecoveringAccess", _descriptor6, this);
      _initializerDefineProperty(this, "moreOneCards", _descriptor7, this);
      _initializerDefineProperty(this, "card", _descriptor8, this);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    get canSelecthAnotherType() {
      return this.model.options.canSelecthAnotherType;
    }
    async isNfcEnabled() {
      const capabilities = await this.provider.fetch('device', 'getCapabilities');
      return (0, _object.get)(capabilities, 'isNfcEnabled');
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.resource = this.model.options.resource;
      const authenticationresponse = JSON.parse(atob(this.resource.authenticationresponse));
      this.challenge = authenticationresponse.challenge[0];
      this.publicServerKey = authenticationresponse.publicKey[0];
      this.isRecoveringAccess = this.resource.smc === USER_CREDENTIALS_SMC;
    }
    async didInsertElement() {
      super.didInsertElement(...arguments);
      const cards = await this.getCards();
      this.moreOneCards = Boolean((cards === null || cards === void 0 ? void 0 : cards.length) > 1);
      if (cards !== null && cards !== void 0 && cards.length) {
        this.card = (0, _object.get)(cards, 'firstObject');
      }
      this.initNFC();
    }
    async willDestroy() {
      super.willDestroy(...arguments);
      this.startNFC.cancelAll();
      this.stopNFC();
    }
    async initNFC() {
      if (!(await this.isNfcEnabled())) {
        try {
          await this.modal.open('safe-tap-settings', {
            canSelecthAnotherType: this.canSelecthAnotherType
          });
        } catch (e) {
          if (e instanceof _userChooseSignatureMethod.UserChooseSignatureMethodError) {
            return this.anotherType();
          }
          if (!(await this.isNfcEnabled())) {
            return this.closeModal();
          }
        }
      }
      try {
        return await this.startNFC.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }
      return this.closeModal();
    }
    getSafeTapSdkError(e) {
      const [chunk] = e.errorInfo.response.split('-');
      return chunk.split(' ')[1].trim();
    }
    async showError(errorType) {
      try {
        await this.modal.open('safe-tap-error', {
          errorType
        });
        this.reject();
      } catch (e) {
        if (e instanceof _userChooseSignatureMethod.UserChooseSignatureMethodError) {
          this.anotherType();
        }
        if (e instanceof _safetapTechnical.SafeTapTechnicalError) {
          this.closeModal();
        }
      }
    }
    async handleError(e) {
      if (e.code === 'SDK') {
        const sdkError = this.getSafeTapSdkError(e);
        if (sdkError === 'E001') {
          await this.showError(_constants.SAFETAP_ERROR_TYPES.READING_ERROR);
        } else {
          await this.showError(_constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR);
        }
      } else {
        await this.showError(_constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR);
      }
    }
    async getCards() {
      return (await this.provider.pull('valid-cards', 'getValidCards')) || [];
    }
    *startNFC() {
      const {
        challenge,
        publicServerKey
      } = this;
      let signed = false;
      do {
        try {
          const result = yield this.slinky.send('SafeTap.init', {
            challenge,
            publicServerKey
          });
          const signedData = result.transactionsData.replace(/\n/g, '');
          const publicKey = result.publicKey.replace(/\n/g, '');
          const authenticationdata = "signedData=".concat(signedData, ",publicKey=").concat(publicKey);
          this.resource.headers.authenticationdata = authenticationdata;
          this.resource.error = false;
          const data = yield this.resource.retry();
          signed = true;
          this.resolve(data);
        } catch (e) {
          yield this.handleError(e);
        }
      } while (!signed);
    }
    async stopNFC() {
      try {
        await this.slinky.send('SafeTap.cancel');
      } catch {
        // nope
      }
    }
    async openAvailableCards() {
      this.stopNFC();
      try {
        await this.modal.open('card-list');
      } catch {
        // nope
      }
      try {
        await this.startNFC.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }
    }
    closeModal() {
      this.reject();
    }
    anotherType() {
      this.reject({
        chooseAnotherType: true
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "experience", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'A';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isRecoveringAccess", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "moreOneCards", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "card", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "startNFC", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "startNFC"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopNFC", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopNFC"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openAvailableCards", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openAvailableCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "anotherType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "anotherType"), _class.prototype)), _class);
  _exports.default = ModalSafeTapOtpComponent;
});